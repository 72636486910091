body {
  margin: 0;
  font-family: 'Montserrat', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

h1 {
  margin: 0 auto 32px;
  font-size: 36px;
  font-family: 'Libre Baskerville', serif;
}

h3 {
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  border-bottom: 2px solid #fff;
}
